module.exports = [{
      plugin: require('/var/www/html/mindbodysoulyoga/gatsby/node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-QCNLW5RTE"]},
    },{
      plugin: require('/var/www/html/mindbodysoulyoga/gatsby/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"static/FAV.png","start_url":"/","display":"standalone"},
    },{
      plugin: require('/var/www/html/mindbodysoulyoga/gatsby/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
