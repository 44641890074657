// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-notfound-js": () => import("/var/www/html/mindbodysoulyoga/gatsby/src/templates/notfound.js" /* webpackChunkName: "component---src-templates-notfound-js" */),
  "component---src-templates-index-js": () => import("/var/www/html/mindbodysoulyoga/gatsby/src/templates/index.js" /* webpackChunkName: "component---src-templates-index-js" */),
  "component---src-templates-classes-js": () => import("/var/www/html/mindbodysoulyoga/gatsby/src/templates/classes.js" /* webpackChunkName: "component---src-templates-classes-js" */),
  "component---src-templates-about-js": () => import("/var/www/html/mindbodysoulyoga/gatsby/src/templates/about.js" /* webpackChunkName: "component---src-templates-about-js" */),
  "component---src-templates-events-js": () => import("/var/www/html/mindbodysoulyoga/gatsby/src/templates/events.js" /* webpackChunkName: "component---src-templates-events-js" */),
  "component---src-templates-contact-js": () => import("/var/www/html/mindbodysoulyoga/gatsby/src/templates/contact.js" /* webpackChunkName: "component---src-templates-contact-js" */),
  "component---src-templates-callendarpage-js": () => import("/var/www/html/mindbodysoulyoga/gatsby/src/templates/callendarpage.js" /* webpackChunkName: "component---src-templates-callendarpage-js" */),
  "component---src-templates-prices-js": () => import("/var/www/html/mindbodysoulyoga/gatsby/src/templates/prices.js" /* webpackChunkName: "component---src-templates-prices-js" */)
}

